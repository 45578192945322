import { Credentials } from '../../types/auth';

export class Login {
  static readonly type = '[Auth] Login';
  constructor(public credentials: Credentials) {}
}

export class Logout {
  static readonly type = '[Auth] Logout';
}

export class SetUserDivisions {
  static readonly type = '[Auth] Set user divisions';
}

export class SetUserActiveDivision {
  static readonly type = '[Auth] Set user active division';
  constructor(public id: number) {}
}
