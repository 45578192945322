import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { AuthService } from '@shared/services/auth.service';
import { AuthStateModel } from './auth.typings';
import {
  Login,
  Logout,
  SetUserActiveDivision,
  SetUserDivisions,
} from './auth.actions';

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    token: null,
    user: null,
    divisions: [],
  },
})
@Injectable()
export class AuthState {
  constructor(private readonly auth: AuthService) {}

  @Action(Login)
  login(ctx: StateContext<AuthStateModel>, action: Login) {
    return this.auth.login(action.credentials).pipe(
      tap(res => {
        ctx.patchState({
          token: res.jwtToken,
          user: res.user,
        });
      }),
    );
  }

  @Action(SetUserDivisions)
  setUserDivisions(ctx: StateContext<AuthStateModel>) {
    return this.auth.getUserDivisions().pipe(
      tap(divisions => {
        ctx.patchState({
          divisions,
        });
      }),
    );
  }

  @Action(SetUserActiveDivision)
  setUserActiveDivision(
    ctx: StateContext<AuthStateModel>,
    action: SetUserActiveDivision,
  ) {
    const state = ctx.getState();
    ctx.patchState({
      user: state.user
        ? {
            ...state.user,
            activeDivisionId: action.id,
          }
        : null,
    });
  }

  @Action(Logout)
  logout(ctx: StateContext<AuthStateModel>) {
    ctx.patchState({ user: null, token: null });
  }

  @Selector()
  static getUser(state: AuthStateModel) {
    return state.user;
  }

  @Selector()
  static getToken(state: AuthStateModel) {
    return state.token;
  }

  @Selector()
  static isAuthenticated(state: AuthStateModel) {
    return !!state.user?.email;
  }

  @Selector()
  static getUserActiveDivision(state: AuthStateModel) {
    return state.user?.activeDivisionId;
  }

  @Selector()
  static getUserDivisions(state: AuthStateModel) {
    return state.divisions;
  }
}
